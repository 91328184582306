(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('./autoreg.js');

$(document).ready(function () {
    $('article').prependTo($('.main-text'));
    $('article h1').appendTo($('.for-h'));

    //menu toggle
    $('#menu-toggle-but').click(function () {
        $(this).toggleClass('open');
        $(".topbar-nav").slideToggle('fast');
    });

    $('.slider').slick({
        autoplay: true,
        arrows: false,
        dots: true,
        responsive: [{
            breakpoint: 768,
            settings: {
                arrows: false
            }
        }]
    });
});

},{"./autoreg.js":2}],2:[function(require,module,exports){
'use strict';

$('span[data-link]').click(function () {
    var link = $(this).data('link');
    window.open('/' + atob(link));
});

},{}]},{},[1]);
